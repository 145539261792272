<template>
  <div class="page" :loaded="Boolean(posts && settings)">
    <section class="top-section">
      <div class="wrapper">
        <div class="container">
          <div class="content">
            <h1>Blog articles</h1>
            <h3>Find all our latest blog articles</h3>
          </div>
        </div>
      </div>
    </section>

    <section class="section">
      <div class="wrapper">
        <div class="section-footer">
          <p>You can find all our latest news here and read more of them to stay up to date on our official Medium publications.</p>
        </div>
      </div>
    </section>

    <div class="articles">
      <div class="wrapper">
        <div class="post-container" v-for="(post, i) in (posts.length ? posts : altPosts).slice(0, limit)" :key="post.id">
          <NewsItem :post="post" :size="i > 0 ? 'small' : 'default'" />
        </div>
      </div>
      <div class="load-more" v-if="posts && limit < posts.length">
        <button class="btn" @click="limit = limit + 9">Load more</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Icon from "./Icon.vue";
import DateFormat from "./DateFormat.vue";
import NewsItem from "./NewsItem.vue";

export default {
  name: "News",
  components: {
    Icon,
    DateFormat,
    NewsItem,
  },
  data() {
    return {
      posts: [],
      limit: 9,
    };
  },
  computed: {
    ...mapGetters({
      settings: "getSettings",
      altPosts: 'getPosts',
    }),
    db() {
      return this.$store.state.firebase.firestore;
    },
  },
  mounted() {
    this.postsRef = this.db()
      .collection(`${this.testMode ? "test_" : ""}posts`).onSnapshot((snap) => {
        this.posts = snap.docs.map((post) => {
          const data = post.data();
          return {
            ...data,
            pubDate: new Date(data.pubDate),
          };
        }).sort((a, b) => b.pubDate - a.pubDate);
      });
  },
  beforeDestroy() {
    if (this.postsRef) {
      this.postsRef();
    }
  },
};
</script>

<style scoped>
.section {
  margin-bottom: 168px;
}

.section-title {
  position: relative;
  margin-bottom: 48px;
  padding: 0 96px;
}
.section-title.centered {
  text-align: center;
}
.section-title h3 {
  font-size: 4rem;
  line-height: 1.166666;
  color: var(--Metallic-Seaweed);
}
.section-title h5 {
  font-size: 2.25rem;
  font-weight: 600;
  line-height: 1.166666;
  margin-top: 16px;
}

.section-image {
  position: relative;
  padding-top: 33.42%;
  margin-bottom: 40px;
}
.section-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
}

.section-footer {
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
  padding: 0 96px;
}
.section-footer p::after {
  content: '';
  display: block;
  width: 587px;
  max-width: 100%;
  height: 3px;
  border-radius: 2px;
  background: linear-gradient(90deg, #0A7E8C 0%, #12E5FF 100%);
  margin: 32px auto 40px;
}

.top-section {
  margin-bottom: 96px;
}
.top-section .container {
  position: relative;
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url(../assets/images/wb_bg_news.jpg);
  border-radius: 12px;
  overflow: hidden;
  padding-top: 26.56%;
}
.top-section .container .content {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: #fff;
  padding: 56px 96px;
  border-radius: 12px;
  background: linear-gradient(36deg, rgba(14, 58, 90, 0.80) 13.82%, rgba(14, 58, 90, 0.50) 32.99%, rgba(14, 58, 90, 0.00) 64.59%);
}
.top-section .container .content .logo {
  display: block;
  width: 414px;
  max-width: 100%;
}
.top-section .container .content h1 {
  font-size: 8rem;
  line-height: 0.86;
  margin-bottom: 16px;
}
.top-section .container .content h3 {
  font-size: 4rem;
  line-height: 1.1625;
  max-width: 900px;
}
.top-section .container .content p {
  font-size: 1.125rem;
}

.text-section .text-container {
  display: flex;
  flex-wrap: wrap;
  padding: 0 48px;
}
.text-section .text-container .text-aside {
  width: 42%;
  padding: 0 48px;
  font-size: 2.25rem;
  font-weight: 600;
  line-height: 1.16666666;
}
.text-section .text-container .text-paragraph {
  width: 58%;
  padding: 0 48px;
  font-size: 1.5rem;
  font-weight: 500;
}
.text-section .text-container .text-paragraph p::after {
  content: '';
  display: block;
  height: 3px;
  border-radius: 2px;
  background: linear-gradient(90deg, #0A7E8C 0%, #12E5FF 100%);
  margin: 40px 0;
}

.post-container {
  width: 33.3333333%;
  padding: 0 12px;
  margin-bottom: 64px;
}

.articles .wrapper {
  display: flex;
  flex-wrap: wrap;
}

.load-more {
  display: flex;
  justify-content: center;
  margin-bottom: 64px;
}

@media screen and (max-width: 1280px) {
  .post-container {
    width: 50%;
  }
}

@media screen and (max-width: 1024px) {
  .page-header {
    margin-top: 0;
    padding: 64px;
  }
}

@media screen and (max-width: 850px) {
  .post-container {
    width: 100%;
    padding: 0;
    margin-bottom: 48px;
  }
}
</style>
