<template>
  <div v-if="post" class="article">
    <router-link :to="`/news/${post.seo.slug}`">
      <img :src="getImage(post['content:encoded'])" :alt="post.title" class="article-image">
    </router-link>
    <div class="article-details">
      <div
        class="article-categories"
        v-html="post.categories ? post.categories.join(' - ') : 'News'"
      ></div>
      <router-link :to="`/news/${post.seo.slug}`">
        <h4>{{ post.title }}</h4>
      </router-link>
      <div class="article-author">
        <span>By {{ post.creator }}</span> -
        <DateFormat :date="post.pubDate" />
      </div>
      <div class="article-excerpt" v-textonly="post['content:encoded']"></div>
      <router-link class="btn medium ghost" :to="`/news/${post.seo.slug}`">
        Read more
      </router-link>
    </div>
  </div>
</template>

<script>
import Icon from "./Icon.vue";
import DateFormat from "./DateFormat.vue";

export default {
  name: "NewsItem",
  components: {
    Icon,
    DateFormat,
  },
  props: {
    post: {
      type: Object,
    },
    size: {
      type: String,
      default: 'default',
    },
  },
  methods: {
    getImage(content) {
      const div = document.createElement("div");
      div.innerHTML = content;
      const img = div.querySelector("img");
      if (img && !img.src.includes('_/stat')) {
        return img.src;
      }
      return null;
    },
  },
};
</script>

<style scoped>
.article {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #fff;
  border-radius: 12px;
  transition: all .2s ease-out;
}

#app[no-touch] .article:hover {
  transform: translateY(-8px);
  box-shadow: 0px 24px 32px 0px rgba(170, 170, 170, 0.08);
}

.article .article-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  padding: 32px 24px;
}

.article h4 {
  font-size: 1.5rem;
  margin: 24px 0 12px;
  font-weight: 600;
  transition: color 0.2s ease-in;
}

.article h4:hover {
  color: var(--Metallic-Seaweed);
}

.article .article-categories {
  font-size: 1.125rem;
  text-transform: uppercase;
  color: var(--Metallic-Seaweed);
  min-height: 21px;
}

.article .article-author {
  color: var(--Metallic-Seaweed);
}

.article .article-excerpt {
  margin: 16px 0 32px;
  flex: 1;
  font-size: 1.25rem;
  line-height: 1.4;
}

.article .article-excerpt::before {
  content: '';
  display: block;
  height: 3px;
  border-radius: 2px;
  background: linear-gradient(90deg, #0A7E8C 0%, #12E5FF 100%);
  margin-bottom: 16px;
}

.article .article-image {
  display: block;
  width: 100%;
  height: 278px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  object-fit: cover;
  opacity: 0;
}

.article .article-image[src] {
  opacity: 1;
}

@media screen and (max-width: 1280px) {
  .article .article-image {
    height: 300px;
  }
}

@media screen and (max-width: 720px) {
  .article h4 {
    font-size: 2rem;
  }
  .article .article-image {
    height: 260px;
  }
  .article .article-details {
    margin-top: 24px;
  }
}

@media screen and (max-width: 520px) {
  .article h4 {
    font-size: 1.5rem;
  }
  .article .article-categories {
    font-size: 0.75rem;
  }
  .article .article-details {
    padding: 0;
  }
}
</style>
